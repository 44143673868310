//申请单详情页
<template>
  <div class="sld_apply_info">
    <!-- <MemberTitle :memberTitle="L['申请单详情']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["申请单详情"] }}</h3>

      <div class="outer4 flex-col" v-if="applyInfo.data.applyId">
        <div class="mod3 flex-col">
          <div class="layer3 flex-row">
            <span class="txt10">申请单号：{{ applyInfo.data.applySn }}</span>
            <span class="info16">{{ applyInfo.data.stateValue }}</span>
            <span class="info17">申请时间：{{ applyInfo.data.createTime }}</span>
          </div>
          <div class="layer4 flex-col"></div>
          <span class="word24">设计师信息</span>
          <div class="layer5 flex-row">
            <span class="txt11">设计师名称：</span>
            <span class="word25">{{ applyInfo.data.designerName ? applyInfo.data.designerName : '--' }}</span>
            <span class="word26">用户名：{{ applyInfo.data.memberName }}</span>
            <span class="txt12">手机号码：{{ applyInfo.data.memberMobile }}</span>
          </div>
          <span class="word27">项目信息</span>
          <span class="txt13">{{ applyInfo.data.projectName ? applyInfo.data.projectName : '项目名称： --' }}</span>
          <template v-if="applyInfo.data.state == 4">
            <span class="word27">拒绝理由</span>
            <span class="txt13">{{ applyInfo.data.refuseReason ? applyInfo.data.refuseReason : '--' }}</span>
          </template>
          <!-- 采购中 / 已完成  -->
          <template v-else-if="applyInfo.data.orderList.length > 0">
            <span class="word27">订单信息</span>
            <span class="text14" v-for="item in applyInfo.data.orderList" :key="item">
              订单号：
              <router-link target="_blank" :to="{ path: `/member/order/info`, query: { orderSn: item.orderSn, opState: 'nope' } }"
                class="cart_bottom_right">{{ item.orderSn }}>></router-link>
              <span style="margin-left:10px;" v-if="item.purchaseSn">采购单号：{{ item.purchaseSn }}</span>
            </span>
          </template>
        </div>
      </div>

      <!-- 顶部标题 start-->
      <div class="cart_title flex_row_start_center">
        <div class="cart_title_pre flex_row_start_center">
          <span>图片</span>
        </div>
        <div class="cart_title_pre">
          <span>产品信息</span>
        </div>
        <div class="cart_title_pre">
          <span>描述</span>
        </div>
        <div class="cart_title_pre">
          <span>生产状态</span>
        </div>
        <div class="cart_title_pre">
          <span>价格</span>
        </div>
        <div class="cart_title_pre">
          <span>购买数量</span>
        </div>
      </div>
      <!-- 顶部标题 end-->

      <!-- 列表 start -->
      <div class="cart_lists" v-show="applyInfo.data.goodsList">
        <div class="cart_lists_pre" v-for="(item, index) in applyInfo.data.goodsList" :key="index">
          <!-- <div class="invalid_goods_title">{{ L["失效产品"] }}</div> -->
          <div class="goods_list flex-row">

            <!-- 图片 -->
            <div class="section8">
              <img class="section8_img" :src="item.goodsImage" />
            </div>

            <!-- 规格型号 -->
            <div class="section8 flex-col">
              <span class="txt18">名称：{{ item.goodsName }}</span>

              <br />
              <span class="txt18" v-if="item.specValues">规格：{{ item.specValues }}</span>
              <br />
              <span class="txt18" v-if="item.orderCode">订货编码：{{ item.orderCode }}</span>

            </div>

            <!-- 描述 -->
            <div class="section8 txt19_box">
              <el-scrollbar>
                <div class="flex-col">
                  <span class="txt19" v-for="attr in item.attributeValueList" :key="attr">
                    {{ attr.attributeName }}：{{ attr.attributeValue }}
                  </span>
                </div>
                <span v-show="item.attributeValueList.length == 0"
                  style="text-indent:30px;line-height: 122px;">--</span>
              </el-scrollbar>
            </div>

            <!-- 生产状态 -->
            <div class="section8 flex-col">
              <span class="txt20">{{ item.produceStateValue }}</span>
              <span class="txt20">发货日：{{ item.deliverTime }}天</span>
            </div>

            <!-- 价格 -->
            <div class="section8 flex-col">
              <span class="txt20 textPrice" v-if="item.goodsPrice">￥{{ item.goodsPrice.toFixed(2) }}</span>
              <div class="flex_row_center_center" v-else style="width: 100%;">
                <div class=" type2"></div>

              </div>
            </div>

            <!-- 购买数量 -->
            <div class="section9 flex-col">
              <div class="goods_edit_nem flex_row_center_center">
                {{ item.goodsNum }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 end -->

    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "../../components/MemberTitle";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
export default {
  name: "memberDesignerInfo",
  components: {
    MemberTitle,
  },

  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const route = useRoute();
    const applyId = ref(route.query.id);
    const applyInfo = reactive({ data: {} });

    const getInfo = () => {
      let param = {
        applyId: applyId.value
      }
      proxy
        .$get('v3/member/front/member/purchase/apply/detail', param)
        .then(res => {
          if (res.state == 200) {
            applyInfo.data = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        })
    };

    onMounted(() => {
      getInfo();
    });

    return {
      L,
      applyInfo,
      applyId,
      getInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/designerApplyInfo.scss";
</style>